import { defineMessages } from "react-intl";
export default defineMessages({
  deliberation: {
    id: "a2vote.addvote.add.deliberation",
    defaultMessage: "Add a deliberation",
  },
  add: {
    id: "a2vote.add",
    defaultMessage: "Add",
  },
  deliberation_name: {
    id: "a2vote.addvote.deliberation.name",
    defaultMessage: "Name of the deliberation",
  },
  deliberation_object: {
    id: "a2vote.addvote.deliberation.object",
    defaultMessage: "Object of the deliberation",
  }, 
  add_agenda: {
    id: "a2vote.addvote.add_file",
    defaultMessage: "Add file",
  },
  import_agenda: {
    id: "a2vote.addvote.import_file",
    defaultMessage: "Import file"
  },
  no_file_upload: {
    id: "a2vote.session.no_file_upload",
    defaultMessage: "No file upload",
  },
  vote_title_error:{
    id: "a2vote.title_error",
    defaultMessage: "Title Required"
  },
  vote_description_error:{
    id: "a2vote.description_error",
    defaultMessage: "Must not exceed 255 characters "
  },
  file_error: {
    id: "a2vote.session.file_error",
    defaultMessage: "Oops, an error has occured with this file.",
  },

});
