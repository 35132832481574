import { A2VOTE_API } from "utils/config";
import { getAuthorization } from "utils/token";
import { handleResponse, handleCatch } from "utils/handleResponse";
import moment from 'moment';
import axios from "axios";

export function addSessionAction(form, filters = {}) {
  return dispatch => {
    dispatch({type: "ADD_SESSION_START"});

    const formData = new FormData();
    form.title && formData.append('title', form.title);
    form.description && formData.append('description', form.description);
    form.location && formData.append('location', form.location);
    form.location2 && formData.append('location2', form.location2);
    form.date && formData.append('date', form.date);
    form.file && formData.append('file', form.file);
    form.userListIds.forEach((id) => {
      formData.append('userListIds[]', id);
    });

    axios.request({
      method: 'POST',
      headers: {
        'Authorization': getAuthorization()
      },
      url: `${A2VOTE_API}/session`,
      data: formData,
      onUploadProgress: p => {
        dispatch({type: "ADD_SESSION_PROGRESS", payload: p.loaded / p.total * 100});
      }
    })
    .then(handleResponse)
    .then(() => {
        dispatch({type: "ADD_SESSION_END"});
        //dispatch(getSessionsAction(filters));
      })
      .catch(handleCatch).then(() => {
        dispatch({type: "ADD_SESSION_ERROR"});
      });
  }
}

export function getSessionsAction(filters = {}, orders = {}) {
  return dispatch => {
    dispatch({ type: "GET_SESSIONS_START" });
    const requestedOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        'Authorization': getAuthorization()
      },
    };
    //pagination
    // TODO: Check why orders.limit = undefined when user add a session
    let l;
    if (orders.limit != 25) {
      if (orders.limit == undefined) {
        l = '&l='
      }
      else {
        l = `&l=${orders.limit}`
      }
    }
    else {
      l = '&l='
    }
		const o = orders.offset ? `&o=${orders.offset}` : '&o=';
    //filtering
    const ft = filters.title ? `&ft=${filters.title}` : '&ft=';
    const fd = filters.description ? `&fd=${filters.description}` : '&fd=';
    const fds = filters.startDate ? `&fds=${moment(filters.startDate).utc().format("YYYY-MM-DD")}` : '&fds=';
    const fde = filters.endDate ? `&fde=${moment(filters.endDate).utc().format("YYYY-MM-DD")}` : '&fde=';
    const fl = filters.location ? `&fl=${filters.location}` : '&fl=';
    const fs = filters.status ? `&fs=${filters.status}` : '&fs=';
      fetch(`${A2VOTE_API}/session?${ft}${fd}${fds}${fde}${fl}${fs}${l}${o}`, requestedOptions)
      .then(handleResponse)
      .then((res) => {
        const { status, sessions, totalSessions } = res;
        if (status === 200) {
          dispatch({ type: "GET_SESSIONS_END", payload: {sessions: sessions, totalSessions: totalSessions} });
        } else {
          dispatch({
            type: "GET_SESSIONS_ERROR",
            payload: { errorType: null, message: "an error occurs" },
          });
        }
      })
      .catch(handleCatch);
  }
}

export function editSessionAction(form, sessionId) {
  return dispatch => {
    dispatch({type: "EDIT_SESSION_START"});
    
    const formData = new FormData();
    sessionId && formData.append('sessionId', sessionId);
    form.title && formData.append('title', form.title);
    form.description && formData.append('description', form.description);
    form.location && formData.append('location', form.location);
    form.location2 && formData.append('location2', form.location2);
    form.date && formData.append('date', form.date);
    form.file === false && formData.append('removeFile', true); // false means that we remove current file
    form.file && formData.append('file', form.file);
    form?.userListIds?.forEach((id) => {
      formData.append('userListIds[]', id);
    });

    const requestOptions = {
      method: "PUT",
      headers: {
        'Authorization': getAuthorization()
      },
      body: formData
    }

    fetch(`${A2VOTE_API}/session`, requestOptions)
      .then(handleResponse)
      .then(()=>{
        dispatch({type: "EDIT_SESSION_END"});
      })
      .catch(handleCatch);
  }
}

export function deleteSessionAction(id) {
  return dispatch => {
    dispatch({ type: "DELETE_SESSION_START" });
    const requestedOptions = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        'Authorization': getAuthorization()
      },
    };
    fetch(`${A2VOTE_API}/session/${id}`, requestedOptions)
      .then(handleResponse)
      .then((res) => {
        const { status, sessions } = res;
        if (status === 200) {
          dispatch({ type: "DELETE_SESSION_END", payload: sessions });
        }         
      })
      .catch(handleCatch);
  }
}

export function exportCsvSessionAction(sessionId, sessionTitle) {
  return dispatch => {
    dispatch({ type: "GET_SESSIONS_START" });
    const requestedOptions = {
      method: "GET",
      headers: {
        "Content-type": "application/json",
        'Authorization': getAuthorization()
      }
    };
    fetch(`${A2VOTE_API}/session/export/${sessionId}/${sessionTitle}`, requestedOptions)
    .then(res => res.blob()
      .then(blob => {
        let url = window.URL.createObjectURL(blob);
        let csv = document.createElement('a');
        csv.href = url;
        csv.download = `${sessionTitle}.csv`;
        csv.click();
        dispatch({ type: 'GET_SESSION_END' });
      })
    );
  }
}

export function getSessionVotes(sessionId, openedVote = null) {
  return dispatch => {
    dispatch({ type: "GET_SESSION_VOTES_START"});
    const requestedOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        'Authorization': getAuthorization()
      },
    };
    fetch(`${A2VOTE_API}/vote/s/${sessionId}`, requestedOptions)
      .then(handleResponse)
      .then((res) => {
        const { status, votes } = res;
        if (status === 200) {
          dispatch({ type: "GET_SESSION_VOTES_END", payload: votes || []});
          if(votes && openedVote) {
            const vote = votes.find((v)=>v.id === openedVote.id);
            if(vote) {
              dispatch({type: "SELECT_VOTE_RESULTS", payload: vote});
            }
          }
        } else {
          dispatch({
            type: "GET_SESSION_VOTES_ERROR",
            payload: { errorType: null, message: "an error occurs" },
          });
        }
      })
      .catch(handleCatch);
  }
}

export function deleteSessionVote(session, vote){
  return (dispatch) => {
    dispatch({ type: "DELETE_VOTE_START" });
    const requestedOptions = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: getAuthorization(),
      },
      body: JSON.stringify({
        voteId: vote.id,
        sessionId: session.id
      }),
    };
    fetch( `${A2VOTE_API}/vote`, requestedOptions)
      .then(handleResponse)
      .then((res) => {
        const { status } = res;
        if (status === 200) {
          dispatch({ type: "DELETE_VOTE_END", payload: vote.id});
        }
        else{
          dispatch({
            type: "GET_SESSION_VOTES_ERROR",
            payload: { errorType: null, message: "an error occurs" },
          });
        }
      })
      .catch(handleCatch);
  };
}

export function editAdminAction(admins, sessionId){
  return (dispatch) => {
    dispatch({ type: "EDIT_ADMIN_START" });
    const requestedOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: getAuthorization(),
      },
      body: JSON.stringify({
        admins,
        sessionId
      }),
    };
    fetch( `${A2VOTE_API}/session/admins`, requestedOptions)
      .then(handleResponse)
      .then((res) => {
        const { status } = res;
        if (status === 200) {
          dispatch({ type: "EDIT_ADMIN_END"});
        }
        else{
          dispatch({
            type: "EDIT_ADMIN_ERROR",
            payload: { errorType: null, message: "an error occurs" },
          });
        }
      })
      .catch(handleCatch);
  };
  
}

export function showAddSessionAction(session = null) {
  return dispatch => {
    session ?
      dispatch({ type: "ADD_SESSION_OPEN", payload: session })
    :
      dispatch({ type: "ADD_SESSION_OPEN"});
  }
}

export function showAddAdminAction(session = null) {
  return dispatch => {
    session ?
      dispatch({ type: "ADD_ADMIN_OPEN", payload: session })
    :
      dispatch({ type: "ADD_ADMIN_OPEN"});
  }
}

export function hideAddAdminAction() {
  return dispatch => {
    dispatch({ type: "ADD_ADMIN_CANCEL" });
  }
}

export function hideAddSessionAction() {
  return dispatch => {
    dispatch({ type: "ADD_SESSION_CANCEL" });
  }
}

export function showAddVoteAction(vote = null) {
  return dispatch => {
    dispatch({ type: "ADD_SESSION_VOTE_OPEN", payload: vote });
  }
}

export function hideAddVoteAction() {
  return dispatch => {
    dispatch({ type: "ADD_SESSION_VOTE_CANCEL" });
  }
}

export function selectSessionAction(session = null) {
  return dispatch => {
    dispatch({ type: "SELECT_SESSION", payload: session});
  }
}

export function startSession(session) {
  return dispatch => {
    if(session && session.status === 0) {
      dispatch({ type: "START_SESSION_START"});
      const data = {
        sessionId : session.id
      }
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          'Authorization': getAuthorization()
        },
        body: JSON.stringify(data)
      }
  
      fetch(`${A2VOTE_API}/session/start`, requestOptions)
        .then(handleResponse)
        .then((dt)=>{
          if (dt.status === 200) {
            dispatch({type: "START_SESSION_END", payload: session.id});
          } else {
            // TODO
          }
        })
        .catch(handleCatch);
    }
  }
}

export function endSession(session) {
  return dispatch => {
    if(session?.status === 1) {
      dispatch({ type: "END_SESSION_START"});
      const data = {
        sessionId : session.id
      }
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          'Authorization': getAuthorization()
        },
        body: JSON.stringify(data)
      }  
      fetch(`${A2VOTE_API}/session/end`, requestOptions)
        .then(handleResponse)
        .then((dt)=>{
          if (dt.status === 200) {
            dispatch({type: "END_SESSION_END", payload: session.id});
          } else {
            // TODO
          }
        })
        .catch(handleCatch);
    }
  }
}

export function startVote(session, vote) {
  return dispatch => {
    dispatch({ type: "START_SESSION_VOTE_START"});
    const data = {
      sessionId: session.id,
      voteId: vote.id
    }
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": getAuthorization()
      },
      body: JSON.stringify(data)
    }
    fetch(`${A2VOTE_API}/vote/start`, requestOptions)
      .then(handleResponse)
      .then((dt) => {
        if(dt.status === 200) {
          dispatch({type: "START_SESSION_VOTE_END", payload: vote.id})
        }
        else {
          // TODO
        }
      })
      .catch(handleCatch);
  }
}

export function endVote(session, vote) {
  return dispatch  => {
    dispatch({ type: "END_SESSION_VOTE_START" });
    const data = {
      sessionId: session.id,
      voteId: vote.id
    }
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": getAuthorization()
      },
      body: JSON.stringify(data)
    }
    fetch(`${A2VOTE_API}/vote/end`, requestOptions)
      .then(handleResponse)
      .then((dt) => {
        if(dt.status === 200) {
          dispatch({type: "END_SESSION_VOTE_END", payload: vote.id})
        }
        else {
          // TODO
        }
      })
      .catch(handleCatch);
  }
}