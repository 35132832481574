import { A2VOTE_API } from "../utils/config";
import { getAuthorization } from "utils/token";
import { handleResponse, handleCatch } from "utils/handleResponse";

export function selectVoteResults(vote) {
  return dispatch => {
    dispatch({type: 'SELECT_VOTE_RESULTS', payload: vote});
  }
}

export function exitVoteResults() {
  return dispatch => {
    dispatch({type: 'SELECT_VOTE_RESULTS', payload:  null});
  }
}

export function getVotesResultsUsers() {
  return dispatch => {
    dispatch({ type: "GET_VOTES_RESULTS_USERS_START" });
    const requestedOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        'Authorization': getAuthorization()
      },
    };
    fetch(`${A2VOTE_API}/vote`, requestedOptions)
      .then(handleResponse)
      .then((data) => {
        const { status, results } = data;
        if (status === 200) {
          dispatch({ type: "GET_VOTES_RESULTS_USERS_END", payload: results });
        } else {
          dispatch({
            type: "GET_VOTES_RESULTS_USERS_ERROR",
            payload: { errorType: null, message: "an error occurs" },
          });
        }
      })
      .catch(handleCatch);
  }
}

export function exportSessionResult(voteId, voteTitle) {
  return dispatch => {
    dispatch({ type: "GET_SESSION_VOTES_START" });
    const requestedOptions = {
      method: "GET",
      headers: {
        "Content-type": "application/json",
        'Authorization': getAuthorization()
      }
    };
    fetch(`${A2VOTE_API}/result/export/${voteId}/${voteTitle}`, requestedOptions)
    .then(res => res.blob()
      .then(blob => {
        let url = window.URL.createObjectURL(blob);
        let csv = document.createElement('a');
        csv.href = url;
        csv.download = `${voteTitle}.csv`;
        csv.click();
        dispatch({ type: 'GET_SESSION_VOTES_END' });
      })
    );
  }
}
