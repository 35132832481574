import React, {useState, useEffect} from "react";
import styled from "styled-components";
// style
import theme from 'theme';

const VotesViewerBody = styled.div`
  height: 100%;
  width: 100%;
  border-right: 1px solid ${theme.ui.grey};
`;

const GraphContainer = styled.div`
  height: 70%;
  width: 100%;
  display: flex;
  position: relative;
`;

const Graduation = styled.div`
  width: calc(100% - 64px);
  height: 100%;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  position: absolute;
  z-index: 1;
  top: 41px;
  left: 32px;
`
const Pourcent = styled.div`

`;

const PourcentResult = styled.div`
  padding-bottom: 8px;
`;

const Grade = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  gap: 16px;
`;


const Line = styled.div`
  border-top: 1px dashed ${theme.ui.grey};
  width: 100%;
`;

const LineSolid = styled.div`
  border-top: 1px solid ${theme.ui.grey};
  width: 100%;
`;

const VotesGraph = styled.div`
  width: calc(100% - 32px);
  height: 100%;
  display: flex;
  align-items: end;
  justify-content: center;
  gap: 16px;
  position: absolute;
  top: 32px;
  left: 32px;
`;

const Legend = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: center;
  height: 30%;
  margin-left: 32px;
  gap: 8px;
`;

const LegendBlock = styled.div`
  width: 16px;
  height: 16px;
  border-radius: 3px;
`;
const LegendWaiting = styled(LegendBlock)`
  background-color: ${theme.ui.grey};
`;

const LegendYes = styled(LegendBlock)`
  background-color: ${theme.lightgreen};
`;

const LegendNo = styled(LegendBlock)`
  background-color: ${theme.lightred};
`;

const LegendAbst = styled(LegendBlock)`
  background-color: ${theme.ui.blue};
`;

const LineLegend = styled.div`
  display: flex;
  gap: 8px;
`;

const Title = styled.div``;

const VotesGraphColumn = styled.div`
  height: ${props=>props.percent}%;
  background: ${theme.ui.grey};
  width: 15%;
  border-radius: 5px 5px 0 0;
  color: ${theme.white};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: end;
  transition: all .4s;
`;

const YesColumn = styled(VotesGraphColumn)`
  background: ${theme.lightgreen};
`;

const NoColumn = styled(VotesGraphColumn)`
  background: ${theme.lightred};
`;

const AbstentionColumn = styled(VotesGraphColumn)`
  background: ${theme.ui.blue};
`;

const VotesViewer = ({results}) => {

  const [nbVotes, setNbVotes] = useState(0);
  const [votesYes, setVotesYes] = useState(0);
  const [votesNo, setVotesNo] = useState(0);
  const [votesAbstention, setVotesAbstention] = useState(0);
  const [votesWaiting, setVotesWaiting] = useState(0);

  const pctWaiting = nbVotes ? Math.trunc(votesWaiting/nbVotes * 100) : 0;
  const pctYes = nbVotes ? Math.trunc(votesYes/nbVotes * 100) : 0;
  const pctNo = nbVotes ? Math.trunc(votesNo/nbVotes * 100) : 0;
  const pctAbstention = nbVotes ? Math.trunc(votesAbstention/nbVotes * 100) : 0;

  useEffect(() => {
    if(results?.length) {
      setNbVotes(results.length);
      const countAbst = results.reduce((acc, res) => res.simpleResult == 2 ? acc + 1 : acc, 0);
      const countYes = results.reduce((acc, res) => res.simpleResult == 1 ? acc + 1 : acc, 0);
      const countNo = results.reduce((acc, res) => res.simpleResult == 0 ? acc + 1 : acc, 0);
      const countWaiting = results.length - countAbst - countYes - countNo;
      setVotesYes(countYes);
      setVotesNo(countNo);
      setVotesAbstention(countAbst);
      setVotesWaiting(countWaiting);
    }
    else {
      clearResults();
    }
  }, [results])

  const clearResults = () => {
    setNbVotes(0);
    setVotesYes(0);
    setVotesNo(0);
    setVotesAbstention(0);
    setVotesWaiting(0);
  }

  return (
    <VotesViewerBody>
      <GraphContainer>
        <Graduation>
          <Grade>
            <Pourcent>100%</Pourcent>
            <Line></Line>
          </Grade>
          <Grade>
            <Pourcent>75%</Pourcent>
            <Line></Line>
          </Grade>
          <Grade>
            <Pourcent>50%</Pourcent>
            <Line></Line>
          </Grade>
          <Grade>
            <Pourcent>25%</Pourcent>
            <Line></Line>
          </Grade>
          <Grade>
            <Pourcent>0%</Pourcent>
            <LineSolid></LineSolid>
          </Grade>
        </Graduation>
        <VotesGraph>
          <VotesGraphColumn percent={pctWaiting}>
            <PourcentResult>{pctWaiting}%</PourcentResult>
          </VotesGraphColumn>
          <YesColumn percent={pctYes}>
            <PourcentResult>{pctYes}%</PourcentResult>
          </YesColumn>
          <NoColumn percent={pctNo}>
            <PourcentResult>{pctNo}%</PourcentResult>
          </NoColumn>
          <AbstentionColumn percent={pctAbstention}>
            <PourcentResult>{pctAbstention}%</PourcentResult>
          </AbstentionColumn>
        </VotesGraph>
      </GraphContainer>
      <Legend>
        <LineLegend>
          <LegendWaiting></LegendWaiting>
          <Title>** En attente du vote ({votesWaiting})</Title>
        </LineLegend>
        <LineLegend>
          <LegendYes></LegendYes>
          <Title>** Pour ({votesYes})</Title>
        </LineLegend>
        <LineLegend>
          <LegendNo></LegendNo>
          <Title>** Contre ({votesNo})</Title>
        </LineLegend>
        <LineLegend>
          <LegendAbst></LegendAbst>
          <Title>** Abstention ({votesAbstention})</Title>
        </LineLegend>
      </Legend>
    </VotesViewerBody>
  )
}

export default VotesViewer;