import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import theme from "../../../theme";
// Components
import SidePageLayout, { Input } from "../../Layout/SidePage";
import Icon from "utils/icon";

// i18n
import { FormattedMessage } from "react-intl";
import messages from "../messages";
import { editAdminAction, hideAddAdminAction } from "../../../actions/session";
import Button from "../../../components/Button/Button";
import { validateEmail } from "../../../utils/mailValidation";

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const MailSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 5px 0px;
  border: 1px solid ${theme.ui.blue};
  border-radius: 5px;
  padding: 6px 10px;

  & > span {
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;

const RightDiv = styled.div`
  display: flex;
  align-items: center;
`;

const IconStyled = styled(Icon)`
  margin-left: 8px;
`;

const MailError = styled.span`
  color: ${theme.red};
  margin-bottom: 10px;
`;

const AddAdmin = () => {
  // state
  const [admins, setAdmins] = useState([]);
  const [adminToAdd, setAdminToAdd] = useState("");
  const [error, setError] = useState(false);

  // bind state
  const { showAddAdmin } = useSelector((state) => state.session);
  // Bind Actions
  const dispatch = useDispatch();
  const editAdmins = (admins, sessionId) =>
    dispatch(editAdminAction(admins, sessionId));
  const hideAddAdmin = () => dispatch(hideAddAdminAction());

  useEffect(() => {
    if (showAddAdmin && showAddAdmin.admins) {
      const admins = showAddAdmin.admins.map((admin) => admin?.mail);
      setAdmins(admins);
    }
  }, []);

  const resetData = () => {
    hideAddAdmin();
    setAdmins([]);
    setAdminToAdd("");
    setError(false);
  };

  const addAdmin = () => {
    if (adminToAdd && validateEmail(adminToAdd)) {
      setAdmins([...admins, adminToAdd]);
      setAdminToAdd("");
      setError(false);
    } else {
      setError(true);
    }
  };

  const removeAdmin = (index) => {
    const newAdmins = [...admins];
    newAdmins.splice(index, 1);
    setAdmins(newAdmins);
  };

  const submit = () => {
    if (showAddAdmin.id) {
      editAdmins(admins, showAddAdmin.id);
    }
    resetData();
  };

  return (
    <SidePageLayout
      title={<FormattedMessage {...messages.session_admins} />}
      buttonMessage={<FormattedMessage {...messages.session_edit_admins} />}
      onClose={resetData}
      onValid={submit}
    >
      <Container>
        <div>
          <FormattedMessage {...messages.session_edit_admins_desc} />
        </div>
        <Input
          inputError={error}
          onChange={(e) => setAdminToAdd(e.target.value)}
          value={adminToAdd}
        />
        {error && (
          <MailError>
            <FormattedMessage {...messages.session_admin_mail_error} />
          </MailError>
        )}
        <Button type="button" action={() => addAdmin()}>
          <FormattedMessage {...messages.session_admin_add} />
        </Button>
        {admins?.map((admin, index) => (
          <MailSection key={index}>
            <span>{admin}</span>
            <RightDiv>
              {showAddAdmin?.admins[index]?.userId !=
                showAddAdmin?.createdBy?.userId && (
                <IconStyled
                  icon="Cross"
                  size="xs"
                  color="red"
                  onClick={() => removeAdmin(index)}
                />
              )}
            </RightDiv>
          </MailSection>
        ))}
      </Container>
    </SidePageLayout>
  );
};

export default AddAdmin;
