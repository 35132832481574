import { defineMessages } from "react-intl";
export default defineMessages({
  return : {
    id: "a2vote.return",
    defaultMessage: "Return",
  },
  refresh : {
    id: "a2vote.refresh",
    defaultMessage: "Refresh",
  },
  export : {
    id: "a2vote.session.vote_export",
    defaultMessage: "Export (.csv)"
  }
});
