//Local
import { A2VOTE_API } from "utils/config";
import { getAuthorization, getApiRoot } from "utils/token";
import { handleResponse, handleCatch } from "utils/handleResponse";
import { isLogged } from "utils/isLogged";
//Reducer
import {
  CREATE_VOTE_START,
  EDIT_VOTE_START,
  LOAD_VOTE_START,
  LOAD_VOTE_END,
  LOAD_VOTE_ERROR,
  DELETE_VOTE_START,
  LOAD_VOTE_SELECTED_END,
  RESET_VOTE_SELECTED,
} from "src/state/createStore";
import axios from "axios";

export const createVote = (form, session = null) => {
  return (dispatch) => {
    dispatch({ type: "ADD_VOTE_START" });    

    const formData = new FormData();
    form.title && formData.append('title', form.title);
    form.description && formData.append('description', form.description);
    form.file === false && formData.append('removeFile', true); // false means that we remove current file
    form.file && formData.append('file', form.file);
    session && formData.append('session', session);

    axios.request({
      method: 'POST',
      headers: {
        'Authorization': getAuthorization()
      },
      data: formData,
      url: `${A2VOTE_API}/vote`,
      onUploadProgress: p => {
        dispatch({type: "ADD_VOTE_PROGRESS", payload: p.loaded / p.total * 100});
      }
    })
    .then(handleResponse)
    .then((res) => {
      const { status } = res;
      if (status === 200) {
        dispatch({ type: "ADD_VOTE_END" });
      } else {
        dispatch({
          type: LOAD_VOTE_ERROR,
          payload: { errorType: null, message: "an error occurs" },
        });
      }
    })
    .catch(handleCatch);
  };
};

export const editVote = (form, voteId, session) => {
  return (dispatch) => {
    dispatch({ type: EDIT_VOTE_START });

    const formData = new FormData();
    voteId && formData.append('id', voteId);
    form.title && formData.append('title', form.title);
    form.description && formData.append('description', form.description);
    form.file && formData.append('file', form.file);
    session && formData.append('session', session);

    const requestedOptions = {
      method: "PUT",
      headers: {
        Authorization: getAuthorization(),
      },
      body: formData,
    };

    fetch(`${A2VOTE_API}/vote`, requestedOptions)
      .then(handleResponse)
      .then((res) => {
        const { status } = res;
        if (status === 200) {
          dispatch({ type: "EDIT_VOTE_END" });
        } else {
          dispatch({
            type: LOAD_VOTE_ERROR,
            payload: { errorType: null, message: "Une erreur est survenue" },
          })
        }
      })
      .catch(handleCatch);
  };
};

export const getAllVotes = () => {
  return (dispatch) => {
    dispatch({ type: LOAD_VOTE_START });

    const user = isLogged();

    const requestedOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: user && getAuthorization(),
      },
    };

    fetch(`${A2VOTE_API}/vote`, requestedOptions)
      .then(handleResponse)
      .then((res) => {
        const { status, votes } = res;
        if (status === 200) {
          dispatch({ type: LOAD_VOTE_END, payload: votes });
        } else {
          dispatch({
            type: LOAD_VOTE_ERROR,
            payload: { errorType: null, message: "Une erreur est survenue" },
          });
        }
      })
      .catch(handleCatch);
  };
};

export const getVoteFromId = (id) => {
  return (dispatch) => {
    dispatch({ type: LOAD_VOTE_START });

    const requestedOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: getAuthorization()
      },
    };

    fetch(`${A2VOTE_API}/vote/${id}`, requestedOptions)
      .then(handleResponse)
      .then((res) => {
        const { status, vote } = res;
        if (status === 200) {
          dispatch({ type: LOAD_VOTE_SELECTED_END, payload: vote });
        } else {
          dispatch({
            type: LOAD_VOTE_ERROR,
            payload: { errorType: null, message: "Une erreur est survenue" },
          });
        }
      })
      .catch(handleCatch);
  };
};

export const resetVote = () => {
  return (dispatch) => {
    dispatch({ type: RESET_VOTE_SELECTED });
  };
};

export const getAllVotesFromUser = (userId) => {
  return (dispatch) => {
    dispatch({ type: LOAD_VOTE_START });

    const requestedOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    };
    fetch(`${A2VOTE_API}/vote/user/${userId}`, requestedOptions)
      .then(handleResponse)
      .then((res) => {
        const { status, votes } = res;
        if (status === 200) {
          dispatch({ type: LOAD_VOTE_END, payload: votes });
        } else {
          dispatch({
            type: LOAD_VOTE_ERROR,
            payload: { errorType: null, message: "Une erreur est survenue" },
          });
        }
      })
      .catch(handleCatch);
  };
};