import { defineMessages } from "react-intl";
export default defineMessages({
  launch: {
    id: "a2vote.viewvotes.launch",
    defaultMessage: "Launch the deliberation vote",
  },
  finish: {
    id: "a2vote.viewvotes.finish",
    defaultMessage: "End the deliberation vote",
  },
  load: {
    id: "a2vote.viewvotes.load",
    defaultMessage: "Load votes",
  },
  result: {
    id: "a2vote.viewvotes.result",
    defaultMessage: "Results",
  },
});
