import React, { useState, useEffect, forwardRef, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
// Tools
import styled from "styled-components";
import theme from "theme";
import Icon from "utils/icon";
import "react-datepicker/dist/react-datepicker.css";
// i18n
import { FormattedMessage } from "react-intl";
import messages from "../messages";
// Actions
import { selectSessionAction, showAddSessionAction, deleteSessionAction } from 'actions/session';
// Components
import Button from "components/Button/Button";
import DatePickerDS from "./components/DatePickerDS";
import DatePickerDE from "./components/DatePickerDE";
import { showAddAdminAction } from "../../../actions/session";

const LayoutFilter = styled.div`
  background-color: ${theme.black}80;
  opacity: 70%;
  position: absolute;
  top: 0;
  height: 100vh;
  width: 100vw;
  z-index: 1;
`;

const Container = styled.div`
  width: 100%;
  height: calc(100% - 235px);
  overflow-y: scroll;
  position: relative;
  border-top: 1px solid ${theme.ui.grey};
  margin-top: 16px;

  @media(max-width: 768px) {
    height: calc(100% - 235px);
  }
`;

const FilterSection = styled.div`
  padding: 16px 32px;
  display: flex;
  justify-content: space-between;
  gap: 16px;
  border-bottom: 1px solid ${theme.ui.grey};

  @media(max-width: 768px) {
    display: none;
  }
`;

const PopUpFilter = styled.div`
  position: absolute;
  background-color: ${theme.white};
  display: flex;
  flex-flow: column;
  justify-content: center;
  border-radius: 8px;
  padding: 32px;
  z-index: 2;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  width: 75%;
  gap: 16px;
`;

const TopInfos = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const TitleFilter = styled.div`
  color: ${theme.ui.blue};
  font-weight: bold;
`;

const ButtonFilter = styled.div`
  display: flex;
  flex-flow: row;
  background-color: ${theme.ui.blue};
  border-radius: 5px;
  padding: 8px;
  gap: 8px;
  align-items: center;
  color: ${theme.white};
  margin: 16px 32px 0;
  width: fit-content;
  cursor: pointer;

  @media(max-width: 768px) {
    margin: 16px 16px 0;
  }
`;

const ButtonsFilter = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 8px;
  width: fit-content;
  width: 100%;

  @media(max-width: 768px) {
    display: flex;
    flex-flow: column;
  }
`;

const SeeResults = styled.div`
  display: flex;
  flex-flow: row;
  background-color: ${theme.ui.blue};
  border-radius: 5px;
  padding: 8px;
  gap: 8px;
  align-items: center;
  color: ${theme.white};
  cursor: pointer;
  justify-content: center;
  `;

const DeleteFilter = styled.div`
  display: flex;
  flex-flow: row;
  background-color: ${theme.red};
  border-radius: 5px;
  padding: 8px;
  gap: 8px;
  align-items: center;
  color: ${theme.white};
  cursor: pointer;
  justify-content: center;
`;


const Filter = styled.div`
  width: 100%;
  display: flex;
  flex-flow: column;
  gap: 8px;
`;

const DateFilter = styled.div`
  display: flex;
  flex-flow: row;
  gap: 16px;
  width: 100%;

  @media(max-width: 768px) {
    justify-content: flex-start;
    display: flex;
    flex-flow: column;
  }
`;

const InputDate = styled.div`
  border: 1px solid ${theme.ui.grey};
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 5px;
  padding: 8px;
`;

const CustomDateInput = styled.input`
  border: none;
  width: 100%;

  &:focus {
    display: none;
  }
`;

const StartDate = styled.div`
  display: flex;
  flex-flow: column;
  gap: 8px;
  width: 50%;

  @media(max-width: 768px) {
    width: 100%;
  }
`;

const EndDate = styled.div`
  display: flex;
  flex-flow: column;
  gap: 8px;
  width: 50%;
  @media(max-width: 768px) {
    width: 100%;
  }
`;

const Cross = styled.div`
  cursor: pointer;
`;

const CrossFilter = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: flex-end;
`;

const Input = styled.div`
  border-radius: 5px;
  border: 1px solid ${theme.ui.grey};
  padding: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const TextArea = styled.div`
  border-radius: 5px;
  border: 1px solid ${theme.ui.grey};
  padding: 8px;
  gap: 8px;
  display: flex;
  justify-content: space-between;
`;

const InputFilter = styled.input`
  border: none;
  width: 100%;
  outline: none;
  margin-left: 8px;

  @media(max-width: 768px) {

  }
`;

const TextAreaFilter = styled.textarea`
  border: none;
  width: 100%;
  outline: none;
  padding: 0;
  font-family: sans-serif;

  @media(max-width: 768px) {

  }
`;

const SessionLine = styled.div`
  padding: 16px 32px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  border-bottom: 1px solid ${theme.ui.grey};

  &:last-child {
    border-bottom: 0px;
  }

  @media(max-width: 768px) {
    padding: 16px;
    flex-direction: column;
  }
`;

const SessionItems = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: space-between;
`;

const Infos = styled.div`
  display: flex;
  flex-flow: column;
  gap: 8px;
`;

const Title = styled.div`
  color: ${theme.darkgray};
  font-weight: bold;
`;

const Item = styled.div`
  color: ${theme.darkgray};

  &.date {
    background-color: ${theme.specific.blue.light};
    color: ${theme.ui.blue};
    border-radius: 5px;
    border: 1px solid ${theme.ui.blue};
    padding: 8px 6px;
    margin-right: 16px;
    white-space: nowrap;

    @media(max-width: 768px) {
      margin-right: 0px;
    }
  }
`;

const Status = styled.div`
  background-color: ${theme.specific.blue.light};
  color: ${theme.ui.blue};
  border-radius: 5px;
  border: 1px solid ${theme.ui.blue};
  padding: 8px 6px;
  margin-right: 16px;
  white-space: nowrap;
`;

const StatusEnded = styled(Status)`
  color: ${theme.darkgray};
  background-color: ${theme.white};
  border-color: ${theme.darkgray};
`;

const StatusCurrent = styled(Status)`
  color: ${theme.green};
  background-color: ${theme.specific.green.light};
  border-color: ${theme.green};
`;

const SessionLink = styled.a`
  color: ${theme.ui.blue};
  font-size: 0.9em;
`;

const Description = styled.div`
  color: ${theme.mediumgray};
  margin-bottom: 16px;
`;

const TimeAndDate = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;

  @media(max-width: 768px) {
    flex-direction: column;
    width: 100%;
    align-items: start;
    margin-bottom: 16px;
    gap: 8px;
  }
`;

const RightDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: end;
  height: 100%;
  width: 100%;

  @media(max-width: 768px) {
    align-items: start;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 8px;
  color: ${theme.darkgray};
  gap: 8px;
`;

const EditButton = styled.div`
  background-color: ${theme.ui.blue};
  border-radius: 5px;
  padding: 1px;
`;

const IconButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  background-color: ${(props) => props?.delete && theme.red || theme.ui.blue};
  color: white;
  cursor: pointer;
  padding: 8px;
  gap: 8px;
  font-size: 1em;
`;

const IconButtonStyled = styled(IconButton)`
  padding: 7px;
`;

const ConsultButton = styled.div`
  display: flex;
  border-radius: 5px;
  align-items: center;
  padding-left: 8px;
  background-color: ${theme.ui.blue};
  cursor: pointer;
`;

export default ({filters = {}, setFilters, getSessions}) => {
  // bind state
  const { sessions, lastAction } = useSelector((state) => state.session);
  const { locale } = useSelector((state) => state.languageReducer);
  const [isOpen, setIsOpen] = useState(false);
  const firstUpdate = useRef(true);
  // bind actions
  const dispatch = useDispatch();
  const selectSession = (session) => dispatch(selectSessionAction(session));
  const addSession = (session) => dispatch(showAddSessionAction(session));
  const addAdmin = (session) => dispatch(showAddAdminAction(session));
  const deleteSession = (sessionId) => dispatch(deleteSessionAction(sessionId));

  const handleFilters = () => {
    handleFilterTitleChange('');
    handleFilterDescriptionChange('');
    handleFilterLocationChange('');
    handleFilterDateStart('');
    handleFilterDateEnd('');
  }

  const handleFilterTitleChange = (value) => {
    let updatedValue = {};
    updatedValue = {title: value}
    setFilters(filters => ({
      ...filters,
      ...updatedValue
    }));
  }

  const handleFilterDescriptionChange = (value) => {
    let updatedValue = {};
    updatedValue = {description: value}
    setFilters(filters => ({
      ...filters,
      ...updatedValue
    }));
  }

  const handleFilterLocationChange = (value) => {
    let updatedValue = {};
    updatedValue = {location: value}
    setFilters(filters => ({
      ...filters,
      ...updatedValue
    }));
  }

  const handleFilterDateStart = (date) => {
    let updatedValue = {};
    updatedValue = {startDate: date}
    setFilters(filters => ({
      ...filters,
      ...updatedValue
    }));
  }

  const handleFilterDateEnd = (date) => {
    let updatedValue = {};
    updatedValue = {endDate: date}
    setFilters(filters => ({
      ...filters,
      ...updatedValue
    }));
  }

  const CustomInput = forwardRef(({ value, onClick }, ref) => (
    <CustomDateInput placeholderText="Sélectionnez une date" value={value} onClick={onClick}></CustomDateInput>
  ));

  const toggleFilter = () => {
    setIsOpen(!isOpen);
  }

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    const timeoutId = setTimeout(() => {
      getSessions(filters);      
    }, 500)
    return () => clearTimeout(timeoutId);
  }, [filters, lastAction]);

  return (
    <>
    {
      isOpen &&
      <>
        <LayoutFilter/>
        <PopUpFilter>
          <TopInfos>
            <TitleFilter>
              <FormattedMessage {...messages.filters} />
            </TitleFilter>
            <CrossFilter>
              <Icon icon="Cross" size="sm" onClick={toggleFilter}/>
            </CrossFilter>
          </TopInfos>
          <Filter>
            <FormattedMessage {...messages.session_filter_title} />
            <Input>
              <Icon icon="Search" size="sm"/>
              <InputFilter
                type="text"
                placeholder="Recherche d'une séance..."
                value={filters.title}
                onChange={e => handleFilterTitleChange(e.target.value)}
              />
              <Cross>
                {
                  filters.title ? <Icon icon="Cross" size="sm" onClick={e => handleFilterTitleChange('')}/> : null
                }
              </Cross>
            </Input>
          </Filter>
          <Filter>
            <FormattedMessage {...messages.session_filter_description} />
            <TextArea>
              <TextAreaFilter
                type="text"
                placeholder="Recherche par description..."
                value={filters.description}
                onChange={e => handleFilterDescriptionChange(e.target.value)}
              />
              <Cross>
                {
                  filters.description ? <Icon icon="Cross" size="sm" onClick={() => handleFilterDescriptionChange('')}/> : null
                }
              </Cross>
            </TextArea>
          </Filter>
          <Filter>
            <FormattedMessage {...messages.session_filter_location} />
            <Input>
              <Icon icon="Search" size="sm"/>
              <InputFilter
                type="text"
                placeholder="Recherche par lieu..."
                value={filters.location}
                onChange={e => handleFilterLocationChange(e.target.value)}
              />
              <Cross>
                {
                  filters.location ? <Icon icon="Cross" size="sm" onClick={() => handleFilterLocationChange('')}/> : null
                }
              </Cross>
            </Input>
          </Filter>
          <DateFilter>
            <StartDate>
              <FormattedMessage {...messages.session_filter_date_start} />
              <InputDate>
                <DatePickerDS
                  filters={filters}
                  handleChange={date => handleFilterDateStart(date)}
                  locale={locale}
                  customInput={<CustomInput />}
                />
                <Cross>
                  {
                    filters.startDate ? <Icon icon="Cross" size="sm" onClick={() => handleFilterDateStart('')}/> : null
                  }
                </Cross>
              </InputDate>
            </StartDate>
            <EndDate>
              <FormattedMessage {...messages.session_filter_date_end} />
              <InputDate>
                <DatePickerDE
                  filters={filters}
                  handleChange={date => handleFilterDateEnd(date)}
                  locale={locale}
                  customInput={<CustomInput />}
                />
                <Cross>
                  {
                    filters.endDate ? <Icon icon="Cross" size="sm" onClick={() => handleFilterDateEnd('')}/> : null
                  }
                </Cross>
              </InputDate>
            </EndDate>
          </DateFilter>
          <ButtonsFilter>
            <DeleteFilter onClick={handleFilters}>
              <Icon icon="Bin" size="sm"/>
              <FormattedMessage {...messages.delete_filters} />
            </DeleteFilter>
            <SeeResults onClick={toggleFilter}>
              <Icon icon="Eye" size="sm"/>
              <FormattedMessage {...messages.view_results} />
            </SeeResults>
          </ButtonsFilter>
        </PopUpFilter>
      </>
    }
      <Container>
        <ButtonFilter onClick={toggleFilter}>
          <Icon icon="Filter" size="sm"/>
          <FormattedMessage {...messages.filters} />
        </ButtonFilter>
        {
          sessions.map((session) => {
            const options = { year: 'numeric', month: 'long', day: 'numeric' };
            const date = locale == "fr" ? new Date(session.date).toLocaleDateString('fr-FR', options) : new Date(session.date).toLocaleDateString('en-EN', options);
            const time = locale == "fr" ? new Date(session.date).toLocaleTimeString('fr-FR', { hour: '2-digit', minute: '2-digit' }) : new Date(session.date).toLocaleTimeString('en-EN', { hour: '2-digit', minute: '2-digit' });
            return (
              <SessionLine key={session.id}>
                <SessionItems>
                <Infos>
                  <Title>{session.title}</Title>
                  <Description>{session.description}</Description>
                </Infos>
                <TimeAndDate>
                  <Item className="date">{date} - {time}</Item>
                  {
                    session.status === 1 &&
                      <StatusCurrent>en cours</StatusCurrent>
                  }
                  {
                    session.status === 2 &&
                      <StatusEnded>terminé</StatusEnded>
                  }
                  <Item>
                    <div>
                      <FormattedMessage {...messages.session_location} /> {session?.location || <FormattedMessage {...messages.none} />}
                    </div>
                    {
                      session?.location2 &&
                        <div>
                          <FormattedMessage {...messages.session_location2} /> : <SessionLink href={session.location2} target="_blank">{session.location2}</SessionLink>
                        </div>
                    }
                  </Item>
                </TimeAndDate>
                </SessionItems>
                <RightDiv>
                  <ButtonContainer>
                    {
                      session?.status === 0 &&
                        <IconButton delete={true} onClick={() => deleteSession(session.id)}>
                          <Icon icon="Bin2" size="sm"/>
                        </IconButton>
                    }
                    {
                      session?.status === 0 &&
                        <EditButton>
                          <Button onClick={() => addSession(session)}>
                            <Icon icon="EditPen" size="sm"/>
                          </Button>
                        </EditButton>
                    }
                    <IconButtonStyled onClick={() => addAdmin(session)}>
                      <Icon icon="MultipleUsersAdd" height={18} width={18}/>
                    </IconButtonStyled>
                    <ConsultButton onClick={() => selectSession(session)}>
                      <Icon icon="Eye" size="sm"/>
                      <Button>
                        <FormattedMessage {...messages.consult_session} />
                      </Button>
                    </ConsultButton>
                  </ButtonContainer>
                  {/*TODO: get vote count*/}
                  {/* <ButtonContainer> 
                    <FormattedMessage {...messages.vote_count} /> : {session?.votes?.length} 
                  </ButtonContainer> */}
                </RightDiv>
              </SessionLine>
            )
          })
        }
      </Container>
    </>
  );
}