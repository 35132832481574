import React from "react";
import PrivateRoute from "components/PrivateRoute";
// Containers
import Session from "containers/Session";
import UserLogged from "../../containers/userLogged";
const S = () => {
  return (
    <UserLogged>
      <PrivateRoute component={Session} />
    </UserLogged>
  );
};

export default S;
