import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
// tools
import { A2VOTE_API } from "utils/config";
// Actions
import { 
  createVote as createVoteAction,
  editVote as editVoteAction
} from "actions/vote";
import {
  hideAddVoteAction,
} from "actions/session";
// Components
import SidePageLayout, { Input, TextArea, Label, IconStyled, FileContainer, FileName } from "containers/Layout/SidePage";
// i18n
import { FormattedMessage } from "react-intl";
import messages from "./messages";
import theme from "../../../../theme";
import styled from "styled-components";
import { Lazyload } from "../../../../components/lazyload/lazyload";

const MessageError = styled.div`
  color: ${theme.red};
  margin: 0 0 6px
`;

const AddVote = () => {
  // state
  const [formData, setFormData] = useState({title: "", description: ""});
  const [file, setFile] =  useState(null);
  const [filename, setFilename] = useState(null);
  const [titleError, setTitleError] = useState(false);
  const [descriptionError, setDescriptionError] = useState(false);
  const [fileError, setFileError] = useState(false);
  // bind state
  const { selectedSession } = useSelector((state) => state.session); 
  const { showAddVote } = useSelector((state) => state.session); 
  // bind actions
  const dispatch = useDispatch();
  const createVote = (formData) => dispatch(createVoteAction(formData, selectedSession.id))
  const editVote = (formData, voteId) => dispatch(editVoteAction(formData, voteId, selectedSession.id))
  const hideAddVote = () => dispatch(hideAddVoteAction());

  useEffect(() => {
    if (showAddVote?.id) {
      setFormData({
        ...formData,
        title: showAddVote.title,
        description: showAddVote.description,
        filename: showAddVote.filename
      })
    }
    if (showAddVote?.filename) {
      setFilename(showAddVote.filename);
      checkFile(showAddVote.filename);
    }
  }, [showAddVote])

  const resetData = () => {
    setFormData({});
    setFile(null);
    setFilename(null);
    hideAddVote()
  }

  const handleChange = (key, data) => {
    setFormData({...formData, [key]:data})
  }

  const checkFile = async (filename = false) => {
    const status = await fetch(
      filename ? `${A2VOTE_API}/file/o/${filename}` : getFileSource()
    ).then((res) => res.status);
    if (status != 200) {
      setFileError(true);
    }
  };

  const handleFileChange = (e) => {
    let theFile = null;
    if(e.target.files && e.target.files[0]) {
      theFile = e.target.files[0];
    }
    setFile(URL.createObjectURL(theFile));
    setFilename(theFile.name)
    handleChange("file", theFile);
  }

  const handleSubmit = () => {
    if(showAddVote.id) {
      if ((formData?.description?.length <= 255) || (formData?.description == null) || (formData?.description == "")) {
        setDescriptionError(false)
        editVote(formData, showAddVote.id);
        resetData()
      }
      else{
        setDescriptionError(true)
      }
    }
    else {
      if ((formData?.title) && (formData?.description?.length <= 255)) {
        setTitleError(false)
        setDescriptionError(false)
        createVote(formData);
        resetData()
      } else  if (!(formData?.title) && (formData?.description?.length <= 255)){
        setTitleError(true);
        setDescriptionError(false)
      }
      else  if ((formData?.title) && (formData?.description?.length > 255)){
        setTitleError(false);
        setDescriptionError(true)
      }
      else{
        setTitleError(true);
        setDescriptionError(true)
      }
    }
  }

  const displayFile = () => {
    if(filename) {
      let pdfSrc = file;
      if (!pdfSrc) {
        pdfSrc = getFileSource()
      }
      if(filename.toLowerCase().includes('.pdf')) {
        return <iframe title="pdf-viewer" src={pdfSrc+"#toolbar=0"} width="100%" height="500px"  frameBorder="0"/>
      } else {
        return <Lazyload src={pdfSrc} alt=""/>
      }
    }
    return null;
  }

  const getFileSource = () => {
    if(filename){
      return `${A2VOTE_API}/file/o/${filename}`;
    }
  };

  const removeFile = () => {
    setFile(null);
    setFilename(null);
    setFileError(false);
    // Does session has file and we remove it ?
    // false => session has file but we remove it
    // null => session file stays empty
    if(showAddVote?.filename) {
      handleChange("file", false);
    }
    else {
      handleChange("file", null);
    }
  }

  return (
    <SidePageLayout
      title={<FormattedMessage {...messages.deliberation} />}
      buttonMessage={<FormattedMessage {...messages.add} />}
      onValid={handleSubmit}
      onClose={hideAddVote}
    >
      <div><FormattedMessage {...messages.deliberation_name} /></div>
      <Input inputError={titleError} type="text" onChange={(e)=>handleChange("title", e.target.value)} value={formData.title}/>
      {titleError && <MessageError> <FormattedMessage  {...messages.vote_title_error} /></MessageError>}
      <div><FormattedMessage {...messages.deliberation_object} /></div>
      <TextArea textAreaError={descriptionError} onChange={(e)=>handleChange("description", e.target.value)} value={formData.description}/>
      {descriptionError && <MessageError> <FormattedMessage {...messages.vote_description_error} /></MessageError>}
      <div><FormattedMessage {...messages.add_agenda} /></div>
      <FileContainer>
        <Input
          id="filesInput"
          type="file"
          onChange={handleFileChange}
          className="file"
          />
        <Label htmlFor="filesInput">
          <IconStyled icon="Upload" size="sm" className="label"/>
          <FormattedMessage {...messages.import_agenda} />
        </Label>
        {
          filename &&
            <IconStyled icon="Cross" size="sm" onClick={() => removeFile()} className="removeFile"/>
        }
      </FileContainer>
      <FileName>{filename ? filename : <FormattedMessage {...messages.no_file_upload} />}</FileName>
      {
        fileError ?
          <div>
            <FormattedMessage {...messages.file_error} />
          </div> :
        displayFile()
      }
    </SidePageLayout>
  )
}

export default AddVote;